import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'

import store from './store'
import Axios from 'axios'
import i18n from '@/plugins/i18n'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import vuetify from '@/plugins/vuetify'
import wysiwyg from "vue-wysiwyg";
import "vue-wysiwyg/dist/vueWysiwyg.css";
import CKEditor from '@ckeditor/ckeditor5-vue';
import lineClamp from 'vue-line-clamp'
import VueYoutube from 'vue-youtube'
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import "regenerator-runtime/runtime.js";
import VTooltip from 'v-tooltip';
import vueDebounce from 'vue-debounce';
import VueApexCharts from 'vue-apexcharts';
import VueLuxon from "vue-luxon";
import resize from "vue-element-resize-detector";
import VueCryptojs from 'vue-cryptojs';
import VueObserveVisibility from 'vue-observe-visibility'
import Donut from 'vue-css-donut-chart';
import 'vue-css-donut-chart/dist/vcdonut.css';

const moment = require('moment')
require('moment/locale/nl')

Vue.prototype.$bus = new Vue();

Vue.prototype.$http = Axios;
Vue.prototype.$http.defaults.baseURL = process.env.VUE_APP_API_URL;
Vue.prototype.$http.defaults.headers.common['Source-System'] = 'dashboard';

const token = localStorage.getItem('token')
if(token) Vue.prototype.$http.defaults.headers.common['Api-Token'] = token

Vue.config.productionTip = false
Vue.config.performance = true

Vue.use(CoreuiVue)
Vue.use(Buefy, { defaultIconPack: 'fa' })
Vue.use(lineClamp)
Vue.use(require('vue-moment'), { moment })
Vue.use(CKEditor)
Vue.use(VueYoutube);
Vue.use(CoolLightBox);
Vue.use(VTooltip, { defaultHtml: false })
Vue.use(vueDebounce);
Vue.use(VueApexCharts);
Vue.use(VueLuxon);
Vue.use(resize);
Vue.use(VueCryptojs);
Vue.use(VueObserveVisibility);
Vue.use(Donut);

Vue.use(wysiwyg, {
  hideModules: {
    "separator": true,
    "justifyLeft": true, 
    "justifyCenter": true,
    "justifyRight": true,
    "table": true, 
    "code": true,
    "image": true
  },
})

Vue.use(VuePlyr, {
  plyr: {
    controls: ['play', 'progress', 'volume', 'mute', 'fullscreen'],
    autoplay: false
  }
})
Vue.component('apexchart', VueApexCharts);

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.mixin({
  methods: {
    openSidebarRight(sidebarType, sidebarData) {
      this.$bus.$emit('open_sidebar_right', {
        type: sidebarType,
        data: sidebarData
      });
    },
    closeSidebarRight() {   
      this.$bus.$emit('close_sidebar_right');      
    },
    removeSidebarHistoryStep() {   
      this.$bus.$emit('remove_sidebar_history_step');      
    }
  },
})

new Vue({
  el: '#app',
  vuetify,
  i18n,
  router,  
  icons,
  store,
  template: '<App/>',
  components: {
    App
  }
})
