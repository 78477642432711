// https://github.com/cretueusebiu/laravel-vue-spa

import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

// Load all locales and remember context
function loadMessages() {
  const context = require.context("@/lang", true, /[a-z0-9-_]+\.json$/i);

  const messages = context
    .keys()
    .map((key) => ({ key, locale: key.match(/[a-z0-9-_]+/i)[0] }))
    .reduce(
      (messages, { key, locale }) => ({
        ...messages,
        [locale]: context(key),
      }),
      {}
    );

  return { context, messages };
}

const { context, messages } = loadMessages();

// Detect default language of browser, and apply it on start
function detectLanguage() {
  let userPlatformLanguage = localStorage.getItem("userPlatformLanguage");

  // First check if the user already has a language tag set
  if(!userPlatformLanguage) {
    // Detect the user language from the browser
    let detectedLanguage = window.navigator.userLanguage || window.navigator.language;

    // Get the correct language code based on the detected language
    let platformLanguageCode = getPlatformLanguageCode(detectedLanguage);
    // Check if there is a translations file available for this language code
    let translationsFile = require('@/lang/' + platformLanguageCode + '.json');

    if(translationsFile) {
      userPlatformLanguage = platformLanguageCode;        
      localStorage.setItem('userPlatformLanguage', platformLanguageCode);
    } else {
      userPlatformLanguage = process.env.VUE_APP_I18N_LOCALE;
      localStorage.setItem('userPlatformLanguage', process.env.VUE_APP_I18N_LOCALE);
    }

    return userPlatformLanguage;
  } else {
    return userPlatformLanguage;
  }
}

function getPlatformLanguageCode(language) {
  // Prepare the language code
  let languageCode = language.split('-')[0];
  let platformLanguageCode = null;    

  if(languageCode === 'bg') platformLanguageCode = 'bg-BG';         // Bulgarian
  else if (languageCode === 'cs') platformLanguageCode = 'cs-CZ';   // Czech
  else if (languageCode === 'da') platformLanguageCode = 'da-DK';   // Danish
  else if (languageCode === 'de') platformLanguageCode = 'de-DE';   // German
  else if (languageCode === 'el') platformLanguageCode = 'el-GR';   // Greek
  else if (languageCode === 'en') platformLanguageCode = 'en-EN';   // English
  else if (languageCode === 'es') platformLanguageCode = 'es-ES';   // Spanish
  else if (languageCode === 'et') platformLanguageCode = 'et-EE';   // Estonian
  else if (languageCode === 'fi') platformLanguageCode = 'fi-FI';   // Finnish
  else if (languageCode === 'fr') platformLanguageCode = 'fr-FR';   // French
  else if (languageCode === 'hu') platformLanguageCode = 'hu-HU';   // Hungarian
  else if (languageCode === 'id') platformLanguageCode = 'id-ID';   // Indonesian
  else if (languageCode === 'it') platformLanguageCode = 'it-IT';   // Italian
  else if (languageCode === 'ja') platformLanguageCode = 'ja-JP';   // Japanese
  else if (languageCode === 'lv') platformLanguageCode = 'lv-LV';   // Latvian
  else if (languageCode === 'nl') platformLanguageCode = 'nl-NL';   // Dutch
  else if (languageCode === 'pl') platformLanguageCode = 'pl-PL';   // Polish
  else if (languageCode === 'pt') platformLanguageCode = 'pt-PT';   // Portuguese
  else if (languageCode === 'ro') platformLanguageCode = 'ro-RO';   // Romanian
  else if (languageCode === 'ru') platformLanguageCode = 'ru-RU';   // Russian
  else if (languageCode === 'sk') platformLanguageCode = 'sk-SK';   // Slovakian
  else if (languageCode === 'sl') platformLanguageCode = 'sl-SI';   // Slovenian
  else if (languageCode === 'sv') platformLanguageCode = 'sv-SE';   // Swedish
  else if (languageCode === 'tr') platformLanguageCode = 'tr-TR';   // Turkish
  else if (languageCode === 'uk') platformLanguageCode = 'uk-UA';   // Ukrainian
  else if (languageCode === 'zh') platformLanguageCode = 'zh-CN';   // Chinese
  
  return platformLanguageCode;
}

// VueI18n instance
const i18n = new VueI18n({
  locale: localStorage.getItem("userPlatformLanguage") || detectLanguage() || process.env.VUE_APP_I18N_LOCALE,
  fallbackLocale: process.env.VUE_APP_I18N_LOCALE || "en-EN",
  messages
});

// Hot updates for localization
if (module.hot) {
  module.hot.accept(context.id, () => {
    const { messages: newMessages } = loadMessages();

    Object.keys(newMessages)
      .filter((locale) => messages[locale] !== newMessages[locale])
      .forEach((locale) => {
        messages[locale] = newMessages[locale];
        i18n.setLocaleMessage(locale, messages[locale]);
      });
  });
}

export default i18n