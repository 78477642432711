import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
    status: '',
    token: localStorage.getItem('token') || '',
    user : {}
	},
	mutations: {
    auth_request(state){
      state.status = 'loading'
    },
    auth_success(state, token, user){
      state.status = 'success'
      state.token = token
      state.user = user
    },
    auth_error(state){
      state.status = 'error'
    },
    logout(state){
      state.status = ''
      state.token = ''
    },
	},
	actions: {
    login({commit}, user){
      return new Promise((resolve, reject) => {
        commit('auth_request')

        axios.post('/v1/core/login/dashboard', {}, {
          auth: {
            username: user.email,
            password: user.password
          }
        })
        .then(res => {
          let loggedInUserData = res.data.data;
          // Set variables based on loggedInUserData
          let user = loggedInUserData.name;
          let companyIdExternal = loggedInUserData.company_id_external;
          let companyImageId = loggedInUserData.company_image_id;
          let headerImageId = loggedInUserData.header_image_id;
          let environmentTag = loggedInUserData.environment_tag;
          let authenticateUser = loggedInUserData.authenticate_user;
          let token = '';

          // Save data to localStorage
          if(companyIdExternal) localStorage.setItem('companyIdExternal', companyIdExternal);
          if(companyImageId) localStorage.setItem('companyImageId', companyImageId);
          if(headerImageId) localStorage.setItem('headerImageId', headerImageId);
          if(environmentTag) localStorage.setItem('environmentTag', environmentTag);

          // Set Axios header
          axios.defaults.headers.common['Company-Id-External'] = companyIdExternal;
          
          // Check if the user has to authenticate
          if(authenticateUser === false) {
            // Update the token value
            token = loggedInUserData['Api-Token'];
            // Save the token to localStorage
            localStorage.setItem('token', token);
            // Set the Axios header
            axios.defaults.headers.common['Api-Token'] = token;
          } else {
            // Save the authentication token to localStorage
            localStorage.setItem('authenticationToken', loggedInUserData['authentication_token']);            
          }
          
          commit('auth_success', token, user)
          resolve(res)
        })
        .catch(err => {
          commit('auth_error')
          localStorage.removeItem('token')
          reject(err)
        })
      })
    },
    authenticate({commit}, user){
      return new Promise((resolve, reject) => {
        commit('auth_request')

        let params = {};
        params.auth_token = user.authenticationToken;

        axios.post('/v1/core/authentication/verify', params)
        .then(res => {
          let verifiedUserData = res.data.data;   
          let token = verifiedUserData['Api-Token'];
          // Save data to localStorage
          localStorage.setItem('token', token);
          // Set the Axios header
          axios.defaults.headers.common['Api-Token'] = token;
          // Remove the authentication token from localStorage
          localStorage.removeItem('authenticationToken');
          
          commit('auth_success', token, user)
          resolve(res)
        })
        .catch(err => {
          commit('auth_error')
          localStorage.removeItem('token')
          reject(err)
        })
      })
    },
    register({commit}, user){
      return new Promise((resolve, reject) => {
        commit('auth_request')
        axios({url: 'http://localhost:8000/api/register', data: user, method: 'POST' })
        .then(resp => {
          const token = resp.data.token
          const user = resp.data.user
          localStorage.setItem('token', token)
          // Add the following line:
          axios.defaults.headers.common['Api-Token'] = token
          commit('auth_success', token, user)
          resolve(resp)
        })
        .catch(err => {
          commit('auth_error', err)
          localStorage.removeItem('token')
          reject(err)
        })
      })
    },
    logout({commit}){
      return new Promise((resolve, reject) => {
        commit('logout')
        localStorage.removeItem('token');
        localStorage.removeItem('companyIdExternal');
        localStorage.removeItem('companyImageId');
        localStorage.removeItem('headerImageId');
        localStorage.removeItem('environmentTag');

        delete axios.defaults.headers.common['Api-Token'];
        delete axios.defaults.headers.common['Company-Id-External'];
        resolve()
      })
    }
	},
	getters : {
	  isLoggedIn: state => !!state.token,
	  authStatus: state => state.status,
	}
})